import { BaseApiResponse } from '@frontend/jetlend-core/src/models/api';
import { Rating } from './rating';

export interface IRatingInfo {
    id?: number;
    rating: Rating;
    interest_rate: number;
    min_interest_rate?: number;
    max_interest_rate?: number;
    pd: number;
    expected_loss: number;
}

export interface IRatingGroupInfo {
    group: string;
    records: IRatingInfo[];
}

export type ApiGroupsResponse = BaseApiResponse & {
    groups: IRatingGroupInfo[];
};

export enum AutoInvestStrategyType {
    Conservative = 'conservative',
    Balanced = 'balanced',
    Aggressive = 'aggressive',
}

export interface IStrategySetting {
    balance_limit?: number;
    rating_a1: number;
    rating_a2: number;
    rating_b1: number;
    rating_b2: number;
    rating_c1: number;
    rating_c2: number;
}

export interface IAutoInvestStrategyState {
    /**
     * Выбранная стратегия в калькулятора АИ.
     */
    selectedStrategyType?: AutoInvestStrategyType;
    /**
     * Информация по выбранной стратегии в калькуляторе АИ.
     */
    strategiesItemData?: IStrategyGroupInfo;
}

export interface IStrategyLimitItem {
    /**
     * Лимит (по оси У).
     */
    part: number;
    /**
     * Рейтинг (A1, A2, B1, B2 и т.д, по оси Х).
     */
    rating_group: string;
}

export interface IExpectedYieldValuesItem {
    /**
     * Вероятность нахождения инвестора в этом уровне доходности (по оси У).
    */
    probability: number;
    /**
     * Ось Х.
     */
    yield_rate: number;
    /**
     * Доходность (по оси Х).
     */
    x: number;
}

export interface SigmaItem {
    /**
     * Ось Х.
     */
    x: number;
    /**
     * Доходность (по оси У).
     */
    yield_rate: number;
}

export interface IStrategyGroupInfo {
    /**
     * Название стратегии.
     */
    title: string;
    /**
     * Описание стратегии.
     */
    description: string;
    /**
     * Мин. ожидаемая чистая доходность.
     */
    expected_min_interest_rate: number;
    /**
     * Макс. ожидаемая чистая доходность.
     */
    expected_max_interest_rate: number;
    /**
     * Стратегия.
     */
    strategy: AutoInvestStrategyType;
    /**
     * Настройки стратегии.
     */
    settings: IStrategySetting[];
    /**
     * Используется для построения сигм в графике "Доверительный интервал" при выборе стратегии
     */
    sigma: SigmaItem[];
    /**
     * Средний npl.
     */
    average_npl: number;
    /**
     * Лимиты стратегий.
     */
    strategy_limits: IStrategyLimitItem[];
    /**
     * Ожидаемая доходность.
     */
    expected_yield_values: IExpectedYieldValuesItem[];
    /**
     * дата, после которой использование Агрессивной и Венчурной стратегии будет под запретом без прохождения теста
     */
    risk_test_block_date: string;
}

export interface IPortfolioRange {
    /**
     * Нижняя граница диапазона портфеля
     */
    min_amount: number;
    /**
     * Верхняя граница диапазона портфеля
     */
    max_amount?: number;
    /**
     * Идентификатор диапазона
     */
    id: number;
}