import { BaseSectionWithDocuments } from '../common/common';

export enum DocumentsSectionType {
    DOCUMENTS = 'documents',
}

export interface IDocumentApiModel {
    title: string;
    url: string;
    type: string;
}

export interface IDocument {
    title?: string;
    description?: string;
    category: DocumentCategory;
    external: boolean;
    file: IDocumentFile;
    url: string | null;
    url_slug: string;
}

export type DocumentsSectionApiModel = BaseSectionWithDocuments<IDocument>

export enum DocumentCategory {
    ALL = 'all',
    DOCUMENT = 'document',
    INFORMATION_DISCLOSURE = 'public',
    ARCHIVE = 'archive',
}

export interface IDocumentFile {
    id: number;
    title: string;
    filename: string;
    filesize: number;
    url: string;
    date: Date;
    modified: Date;
    mime_type: string;
    subtype: string;
    icon: string;
}

export interface IDocumentsState {
    documents: IDocument[];
    filteredDocuments: IDocument[];
    selectedCategory: DocumentCategory;
    search: string;
}

