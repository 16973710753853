import queryString from 'query-string';
import {
    ApiDataResponse,
    ApiDateRangeQuery,
} from '@frontend/jetlend-core/src/models/api';
import { ChartSize } from '@frontend/jetlend-core/src/models/charts';
import { toAPIDate } from '@frontend/jetlend-core/src/formatters/formatUtils';
import moment from 'moment';
import {
    IInvestorsJetLendStatisticsApiModel,
    IInvestorsNplChartPointApiModel,
    IInvestorsReliabilityPointApiModel,
    InvestorsExternalIndicesChartIndexType,
} from '@app/models/investors/investors';
import { apiGetRequest } from '../api';
import { IChartDataApiModel } from '@app/models/investments/investments';

export async function apiGetInvestorsNplChart(size?: ChartSize, query?: ApiDateRangeQuery): Promise<IInvestorsNplChartPointApiModel[]> {
    const finalQuery = {
        size,
        ...query,
    };

    return await apiGetRequest(`~/invest/api/public/stats/charts/npl?${queryString.stringify(finalQuery)}`) as any;
}

export async function apiGetInvestorsReliabilityChart(): Promise<IInvestorsReliabilityPointApiModel[]> {
    return apiGetRequest('~/invest/api/public/stats/charts/reliability');
}

export async function apiGetInvestorsJetLendStatistics(): Promise<IInvestorsJetLendStatisticsApiModel> {
    return await apiGetRequest('~/invest/api/public/stats') as any;
}

export async function apiGetInvestorsJetLendPublicIndicesChart(size = 0): Promise<ApiDataResponse<any>> {
    if (size > 0) {
        const now = moment().add(-size, 'day');

        return apiGetRequest(`~/invest/api/public/indices/jetlend?base_date=${toAPIDate(now)}&invest_type=all&weekend=1`);
    }

    return apiGetRequest('~/invest/api/public/indices/jetlend?invest_type=all&weekend=1');
}

export async function apiGetInvestorsExternalIndicesChart(size = 0, index_name: InvestorsExternalIndicesChartIndexType): Promise<ApiDataResponse<any>> {
    if (size <= 0) {
        return {
            status: 'OK',
            data: [],
        };
    }

    const now = moment().add(-size, 'day');

    return apiGetRequest(`~/invest/api/public/indices/external?index_name=${index_name}&weekend=1&base_date=${toAPIDate(now)}`);
}

export async function apiGetBorrowersLocations(): Promise<ApiDataResponse<IChartDataApiModel>> {
    return apiGetRequest('~/invest/api/public/borrowers_location');
}

