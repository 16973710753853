import React, {
    useCallback,
    useEffect,
} from 'react';
import { ToastModel } from '@frontend/jetlend-core/src/ducks/toasts';
import ToastItem from './ToastItem';

import styles from './toasts.module.scss';

export interface IProps {
    items: ToastModel[];
    onClick?: (id: string) => void;
}

type PositionsState = {
    [order: number]: {
        height: number;
        marginTop: number;
    };
}

let positions: PositionsState = {};

const ToastsList: React.FC<IProps> = props => {
    const {
        items,
        onClick,
    } = props;

    useEffect(() => {
        positions = {};
    }, []);

    const didItemLayoutChanged = useCallback((order: number, height: number, marginTop: number) => {
        positions[order] = {
            height,
            marginTop,
        };
    }, []);

    function evaluatePositionY(index: number): number {
        let result = 0;

        for (let order = 0; order < index; order++) {
            const p = positions[order];

            result += (p.height || 0) + (p.marginTop || 0);
        }

        return result;
    }

    return (
        <div className={styles['toasts-list']}>
            {items && items.map((item, index) =>
                <ToastItem
                    key={item.id}
                    order={index}
                    positionY={evaluatePositionY(index)}
                    onLayoutChanged={didItemLayoutChanged}
                    onClick={onClick}
                    {...item}
                />
            )}
        </div>
    );
};

export default React.memo(ToastsList);