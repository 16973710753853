import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { IAccountStatusDescription } from '@app/models/account';
import { apiGetAccountStatuses } from '@app/services/client/accountService';

export const PREFIX = 'account';

export const accountStatusesHandler = simpleApiHandler<IAccountStatusDescription[]>(
    PREFIX, 'statuses', apiGetAccountStatuses
);

export const reducer = combineReducers({
    ...accountStatusesHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([
        ...accountStatusesHandler.effects,
    ]);
}
