import { collectionApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { simpleSagaHandlerV2 } from '@frontend/jetlend-core/src/ducks/saga_v2';
import {
    put,
    select,
} from 'redux-saga/effects';
import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    isEmptyValue,
    requirePhone,
    required,
} from '@frontend/jetlend-core/src/validators';
import {
    CLIENT_TYPE_CABINET_URLS,
    ClientType,
    CommonStage,
} from '@app/models/common/common';
import {
    apiGetExternalLoginVariants,
    apiPostExternalPhoneRegistration,
} from '@app/services/client/common/oauthService';
import {
    ExternalAuthVariant,
    IExternalAuthVariantApiModel,
    IOauthPhoneRegistrationValues,
    IOauthState,
} from '@app/models/common/oauth';
import { signDataWithSmsSaga } from './sms';
import { ILoginResultApiModel } from '@app/models/common/login';

export const VERSION = 2;
export const PREFIX = 'common/oauth';

/**
 * Хендлер состояния процесса внешней авторизации.
 */
export const externalAuthStateHandler = objectHandler<IOauthState>(
    PREFIX, 'state'
);

/**
 * Сага для инициализации процесса внешней авторизации.
 */
export const externalAuthInitHandler = simpleSagaHandlerV2<{
    clientType: ClientType;
    variant: ExternalAuthVariant;
    token?: string;
    username?: string;
}>(PREFIX, 'init', function* ({
    clientType,
    variant,
    token,
    username,
}) {
    yield put(externalAuthStateHandler.update({
        stage: CommonStage.OauthPhone,
        clientType,
        variant,
        token,
        username,
    }));
});

/**
 * Api handler для получения данных о существующих вариантах авторизации пользователя при помощи сторонних ресурсов.
 * @param clientType - Тип авторизуемого клиента.
 * @returns Список, содержащий информацию о поддерживаемых ресурсах сторонней авторизации.
 */
export const getExternalAuthData = collectionApiHandler<ClientType, IExternalAuthVariantApiModel[], ApiDataResponse<IExternalAuthVariantApiModel[]>>(
    PREFIX, 'external_auth', 'external_auth', apiGetExternalLoginVariants
);

/**
 * Хендлер формы для регистрации номера телефона при регистрации через внешний сервис, если номер не указан во внешнем аккаунте.
 */
export const externalAuthPhoneRegisterFormHandler = simpleFormHandler<IOauthPhoneRegistrationValues>(
    PREFIX, 'phone_register', {
        v2: {
            phone: [ required(), requirePhone() ],
        },
    }, {
        *onBeforeSubmit (values: IOauthPhoneRegistrationValues): Generator<any, IOauthPhoneRegistrationValues, any> {
            const state: IOauthState = yield select(externalAuthStateHandler.selector);
            const {
                clientType,
                token,
                variant,
            } = state;

            const dataToSend: IOauthPhoneRegistrationValues = {
                clientType,
                auth_id: variant,
                auth_token: token,
                ...values,
            };

            // Подписываем данные с помощью sms
            yield put(externalAuthStateHandler.update({
                stage: CommonStage.Sms,
            }));

            const smsId = yield signDataWithSmsSaga(clientType, values.phone, dataToSend, 'oauth-phone-registration');
            if (!smsId) {
                yield put(externalAuthStateHandler.update({
                    stage: CommonStage.OauthPhone,
                }));

                return undefined;
            }

            // Перед отправкой реального запроса на регистрацию, показываем спинер обработки запроса
            yield put(externalAuthStateHandler.update({
                stage: CommonStage.Loading,
                loadingTitle: 'Создание аккаунта...',
            }));

            return {
                sms_id: smsId,
                ...dataToSend,
            };
        },
        apiMethod: apiPostExternalPhoneRegistration,
        onSuccess (response: ApiDataResponse<ILoginResultApiModel>, values) {
            // Перенаправляем пользователя по ссылке или на страницу личного кабинета
            const redirectUri = response.data?.redirect_uri;
            const { clientType } = values;

            if (!isEmptyValue(redirectUri)) {
                document.location.href = redirectUri;
            } else {
                document.location.href = CLIENT_TYPE_CABINET_URLS[clientType];
            }
        },
        *onFormError () {
            // В случае ошибки, возвращаем на страницу ввода номера телефона
            yield put(externalAuthStateHandler.update({
                stage: CommonStage.OauthPhone,
            }));
        },
    }, {}
);
