import {
    call,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
    IApiHandler,
    simpleApiHandler,
} from './api';
import { SimpleSelector } from './props';
import { createType } from './core';

interface ChartState {
    size?: number;
}

export interface IApiChartHandler<TModel> extends IApiHandler<TModel> {
    size: SimpleSelector<number | undefined>;
    // TODO Return type
    updateOptions: (size?: number) => any;
}

/**
 * TODO
 * @param prefix
 * @param key
 * @param apiMethod
 * @param apiDataSelector
 * @returns
 * @category Duck Handlers
 */
export function simpleChartApiHandler<TModel, TResponse>(
    prefix: string,
    key: string,
    apiMethod: (size?: number) => Promise<TResponse> | TResponse,
    apiDataSelector: (response: TResponse) => TModel = (resp: any) => resp?.data as TModel
): IApiChartHandler<TModel> {
    const actionName = key.toUpperCase();

    const getDuck = state => state[prefix];
    const getData = createSelector(getDuck, (data: any) => data?.[key]);

    const size = createSelector(getData, data => data?.size);

    function* wrappedApiMethod() {
        const state: ChartState = yield select(getData);

        return yield call(apiMethod, state?.size);
    }

    const baseHandler = simpleApiHandler<TModel, TResponse>(
        prefix,
        key,
        wrappedApiMethod as any,
        apiDataSelector
    );

    function* update(values: ChartState) {
        yield put(baseHandler.update(values as any));
    }

    const UPDATE_CHART_OPTIONS = createType(prefix, `UPDATE_CHART_OPTIONS_${actionName}`);
    function* updateChartOptionsSaga({ size }) {
        const state: ChartState = yield select(getData);
        if (size === state.size) {
            // DO NOTHING
            return;
        }

        yield update({ size });

        yield put(baseHandler.fetch({ force: true }));
    }

    function* initInternalStateSaga() {
        yield update({ size: 0 });
    }

    const updateOptions = (size?: number) => ({
        type: UPDATE_CHART_OPTIONS,
        size,
    });

    return {
        ...baseHandler,
        size,
        updateOptions,
        reducerInfo: baseHandler.reducerInfo,
        effects: [
            ...baseHandler.effects,
            call(initInternalStateSaga),
            takeEvery(UPDATE_CHART_OPTIONS as any, updateChartOptionsSaga),
        ],
    };
}
