'use client';

import React, { useCallback } from 'react';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import {
    closeToast,
    IToast,
    getToasts,
} from '@frontend/jetlend-core/src/ducks/toasts';
import ToastsList from './ToastsList';

type Props = {
    items: IToast[];

    closeToast: (id: string) => void;
}

const ConnectedToastsList: React.FC<Props> = props => {
    const {
        items,
        closeToast,
    } = props;

    const didToastClicked = useCallback((id: string) => closeToast(id), []);

    return (
        <ToastsList items={items} onClick={didToastClicked} />
    );
};

export default connectFunctionalComponent(ConnectedToastsList, {
    selectors: {
        items: getToasts,
    },
    dispatch: {
        closeToast,
    },
}) as React.FC;