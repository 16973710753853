import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import {
    modalFormHandler,
    simpleFormHandler,
} from '@frontend/jetlend-core/src/ducks/form';
import {
    IAccountUsersApiModel,
    IAccountInfoApiModel,
} from '@app/models/common/account';
import {
    apiGetAccountInfo,
    apiGetAccountUsersData,
    apiPostAccountLogout,
    apiPostAccountSwitch,
} from '@app/services/client/common/accountService';

export const PREFIX = 'common_account';

export const userHandler = simpleApiHandler<IAccountUsersApiModel>(PREFIX, 'users', apiGetAccountUsersData);
export const fetchUser = userHandler.fetch;
export const getUsers = userHandler.selector;
export const accountInfoHandler = simpleApiHandler<IAccountInfoApiModel>(PREFIX, 'info', apiGetAccountInfo);

export const userModalHandler = modalFormHandler(
    PREFIX, 'users_modal', {}, {
        apiMethod: apiPostAccountSwitch,
        onSuccessUpdate: [
            accountInfoHandler,
        ],
    }
);

function refreshPageSaga() {
    window.location.reload();
}

export const logoutAccountFormHandler = simpleFormHandler(
    PREFIX, 'logout', {}, {
        apiMethod: apiPostAccountLogout,
        onSuccess() {
            refreshPageSaga();
        },
    }, {}
);

export const reducer = combineReducers({
    ...userHandler.reducerInfo,
    ...accountInfoHandler.reducerInfo,
    ...logoutAccountFormHandler.reducerInfo,
    ...userModalHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([
        ...userHandler.effects,
        ...accountInfoHandler.effects,
        ...logoutAccountFormHandler.effects,
        ...userModalHandler.effects,
    ]);
}
