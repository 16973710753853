import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import queryString from 'query-string';
import {
    ApiGroupsResponse,
    IPortfolioRange,
    IStrategyGroupInfo,
} from '@app/models/autoinvest';
import { apiGetRequest } from './api';

export async function apiGetAutoinvestRatingInfo(): Promise<ApiGroupsResponse> {
    return await apiGetRequest('~/invest/api/autoinvest/info') as any;
}

export async function apiGetAutoInvestStrategiesInfo(rangeId): Promise<ApiDataResponse<IStrategyGroupInfo[]>> {
    const query = queryString.stringify({
        range: rangeId,
    });
    return apiGetRequest(rangeId ? `~/invest/api/public/autoinvest/strategies/info?${query}` : '~/invest/api/public/autoinvest/strategies/info');
}

export async function apiGetPortfolioRanges(): Promise<ApiDataResponse<IPortfolioRange[]>> {
    return apiGetRequest('~/invest/api/public/autoinvest/strategies/ranges');
}
