import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import { apiGetRequest } from '../api';
import { IInvestedCompany } from '@app/models/common/companies';

/**
 * Запрос информации о компаниях получивших займ
 */
export async function apiGetInvestedCompanies(): Promise<ApiDataResponse<IInvestedCompany[]>> {
    return apiGetRequest('~/invest/api/requests/all/public');
}
