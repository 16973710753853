import { combineReducers } from 'redux';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import {
    ICalculatorState,
    ICreditCalculatorResult,
} from '@app/models/borrowers/borrowers';


export const PREFIX = 'borrowers';

export const calculatorHandler = objectHandler<ICalculatorState>(PREFIX, 'calculator', () => ({
    loanAmount: 10000000,
    loanDuration: 36,
    revenueAmount: 5000000,
    companyAge: true,
    creditHistory: true,
    stableRevenue: true,
    employeeAmount: true,
}));

export const summaryHandler = objectHandler<ICreditCalculatorResult>(PREFIX, 'summary', () => ({
    probability_of_approval: 0,
    min_interest_rate_amount: 0,
    max_interest_rate_amount: 0,
    monthly_payment: 0,
}));

export const reducer = combineReducers({
    ...calculatorHandler.reducerInfo,
    ...summaryHandler.reducerInfo,
});