import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import {
    all,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { call } from '@redux-saga/core/effects';
import { safeSaga } from '@frontend/jetlend-core/src/ducks/utils';
import {
    ApiGroupsResponse,
    IRatingGroupInfo,
    IStrategyGroupInfo,
    IAutoInvestStrategyState,
    AutoInvestStrategyType,
} from '@app/models/autoinvest';
import {
    apiGetAutoinvestRatingInfo,
    apiGetAutoInvestStrategiesInfo,
    apiGetPortfolioRanges,
} from '@app/services/client/autoinvestService';

export const PREFIX = 'autoinvest';

export const RATINGS_LEVEL = {
    [AutoInvestStrategyType.Conservative]: '1-12',
    [AutoInvestStrategyType.Balanced]: '1-15',
    [AutoInvestStrategyType.Aggressive]: '10-18',
};

export const currentStrategyHandler = objectHandler<IAutoInvestStrategyState>(
    PREFIX, 'current_strategy', () => ({
        selectedStrategyType: null,
        strategiesItemData: null,
    })
);

export const autoinvestRatingInfoHandler = simpleApiHandler<IRatingGroupInfo[], ApiGroupsResponse>(
    PREFIX, 'rating_info', apiGetAutoinvestRatingInfo, response => response.groups
);

export const portfolioRangeHandler = objectHandler(PREFIX, 'portfolio-range-state', () => ({
    rangeId: null,
}));

export const getPortfolioRangesHandler = simpleApiHandler(PREFIX, 'portfolio-ranges', apiGetPortfolioRanges);

export const autoInvestStrategiesInfoHandler = simpleApiHandler<IStrategyGroupInfo[]>(
    PREFIX, 'strategies_info', function* () {
        const rangeState = yield select(portfolioRangeHandler.selector);

        return yield call(apiGetAutoInvestStrategiesInfo, rangeState.rangeId);
    }
);

function* updateStrategyState() {
    const strategy = AutoInvestStrategyType.Balanced;
    const strategies = yield select(autoInvestStrategiesInfoHandler.selector);

    const strategiesItemData = strategies?.find(s => s.strategy === strategy);

    yield put(currentStrategyHandler.update({
        selectedStrategyType: strategy,
        strategiesItemData,
    }));
}

/**
 * Обновление диапазонов портфеля, при обновлении информации о стратегиях
 */
function* updateGetPortfolioRanges() {
    const ranges = yield select(getPortfolioRangesHandler.selector);
    if (!ranges) {
        yield put(getPortfolioRangesHandler.fetchUpdate());
    }
}

/**
 * Обновление информации о стратегиях, при обновлении выбранного диапазона
 */
function* updateAutoInvestStrategiesInfoHandler() {
    yield put(autoInvestStrategiesInfoHandler.fetchUpdate());
}

export const reducer = combineReducers({
    ...autoinvestRatingInfoHandler.reducerInfo,
    ...autoInvestStrategiesInfoHandler.reducerInfo,
    ...currentStrategyHandler.reducerInfo,
    ...portfolioRangeHandler.reducerInfo,
    ...getPortfolioRangesHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([
        ...autoinvestRatingInfoHandler.effects,
        ...autoInvestStrategiesInfoHandler.effects,
        ...getPortfolioRangesHandler.effects,
        takeEvery(autoInvestStrategiesInfoHandler.FETCH_DONE, updateStrategyState),
        takeEvery(portfolioRangeHandler.UPDATE_ACTION, safeSaga(updateAutoInvestStrategiesInfoHandler, PREFIX)),
        takeEvery(autoInvestStrategiesInfoHandler.FETCH_DONE, safeSaga(updateGetPortfolioRanges, PREFIX)),
    ]);
}
