import React, {
    useCallback,
    useRef,
    useLayoutEffect,
    useEffect,
    useMemo,
} from 'react';
import {
    ToastRenderState,
    ToastType,
} from '@frontend/jetlend-core/src/ducks/toasts';
import { buildClassNames } from '@ui/utils/classNameUtils';

import styles from './toasts.module.scss';

interface IProps {
    /**
     * Порядковый номер в списке.
     */
    order?: number;
    /**
     * Уникальный идентификатор.
     */
    id?: string;
    /**
     * Текст внутри всплывающего сообщения.
     */
    title: string;
    /**
     * Тип (цветовая схема) всплывающего сообщения.
     */
    type?: ToastType;
    /**
     * Состояние анимации. Корректный путь created -> idle -> closed и в обратную сторону.
     */
    renderState?: ToastRenderState;
    /**
     * Используется для корректной анимации при использовании группы всплывающих подсказок.
     */
    positionY?: number;

    onClick?: (id: string) => void;
    onLayoutChanged?: (order: number, height: number, marginTop: number) => void;
    onDisappear?: (order: number) => void;
}

/**
 * Анимированные всплывающие сообщения.
 */
const ToastItem: React.FC<IProps> = props => {
    const {
        id,
        title,
        type = ToastType.Success,
        renderState,
        onClick,
    } = props;

    const containerRef = useRef<HTMLButtonElement>();

    useLayoutEffect(() => {
        const element = containerRef.current;
        if (element) {
            const computedStyles = window.getComputedStyle(element);
            props.onLayoutChanged && props.onLayoutChanged(props.order, element.clientHeight, computedStyles.marginTop?.endsWith('px') && parseFloat(computedStyles.marginTop) || 0);
        }
    });

    useEffect(() => () => {
        props.onDisappear && props.onDisappear(props.order);
    }, [ props.onDisappear, props.order ]);

    const containerClassName = buildClassNames(styles, [
        'toast',
        `toast--${type}`,
        renderState && `toast--${renderState}`,
    ]);

    const inlineStyles = useMemo(() => {
        const bottom = -containerRef.current?.clientHeight;

        return {
            transform: typeof props.positionY === 'number'
                ? `translateY(${props.positionY}px)`
                : undefined,
            marginBottom: typeof bottom === 'number' && !Number.isNaN(bottom)
                ? bottom
                : undefined,
        };
    }, [ containerRef?.current, props.positionY ]);

    const didClicked = useCallback(() => onClick && onClick(id), [ id, onClick ]);

    return (
        <div style={inlineStyles}>
            <button ref={containerRef} className={containerClassName} onClick={didClicked}>
                <div className={styles['toast__title']}>{title}</div>
            </button>
        </div>
    );
};

export default ToastItem;