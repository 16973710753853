import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import { apiGetRequest } from '@app/services/client/api';
import {
    CreditPortfolioApiModel,
    IChartDataApiModel,
} from '@app/models/investments/investments';

export async function apiGetCreditPortfolioChart(): Promise<ApiDataResponse<CreditPortfolioApiModel>> {
    // return testResponse(MOCK_CREDIT_PORTFOLIO);

    return apiGetRequest('~/invest/api/investments/credit_portfolio');
}

export async function apiGetMarketShareChart(): Promise<ApiDataResponse<IChartDataApiModel>> {
    // return testResponse(MOCK_MARKET_SHARE);

    return apiGetRequest('~/invest/api/public/investments/market_share');
}

export async function apiGetBusinessAreasChart(): Promise<ApiDataResponse<IChartDataApiModel>> {
    // return testResponse(MOCK_BUSINESS_AREAS);

    return apiGetRequest('~/invest/api/public/investments/business_areas');
}
